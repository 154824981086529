import $ from 'jquery';
import { instance } from '@ui/site-scroll';

function handleNav() {
  $('.navbar .toggle').on('click', () => {
    $('.navbar').toggleClass('is-menu-open');
    $('.navbar .btn').addClass('secondary');
  });

  $('.navbar a').on('click', () => {
    $('.navbar').removeClass('is-menu-open');
  });

  $(window).on('resize', () => {
    if ($(window).width() >= 1024) {
      $('.navbar').removeClass('is-menu-open');
      $('.navbar .btn').removeClass('secondary');
    }
  });

  // document.querySelectorAll('footer a:not([target="_blank"])').forEach((link) => {
  //   link.classList.add('empty-btn');
  // });
}

function teamPortfolioResizeDesktop() {
  const portfolios = document.querySelectorAll('section.hero .swiper-slide .portfolio ul');

  portfolios.forEach((portfolio) => {
    const children = portfolio.getElementsByClassName('company');
    if (children.length > 5) {
      portfolio.classList.add('big');
    }
  });
}

function teamPortfolioResizeMobile() {
  const portfolios = document.querySelectorAll('section.hero .swiper.mobile .swiper-slide .portfolio ul');

  portfolios.forEach((portfolio) => {
    const children = portfolio.getElementsByClassName('company');
    if (children.length > 3) {
      portfolio.style.maxHeight = '5.5rem';

      const viewAll = document.createElement('div');
      viewAll.classList.add('view-all');
      viewAll.innerHTML = 'View All';

      portfolio.parentElement.appendChild(viewAll);

      viewAll.addEventListener('click', () => {
        document.querySelector('body').classList.add('portfolio-big');
        portfolio.style.maxHeight = '';
      });

      const backButton = document.querySelectorAll('.swiper-slide .back');

      backButton.forEach((button) => {
        button.addEventListener('click', () => {
          document.querySelector('body').classList.remove('portfolio-big');
          portfolio.style.maxHeight = '5.5rem';
        });
      });
    }
  });
}

function checkForVideo() {
  const cards = document.querySelectorAll('.row > div .video');
  cards.forEach((card) => {
    card.parentElement.parentElement.classList.add('video');
  });
}

function videoControls() {
  const controls = document.querySelectorAll('.video .controls');

  controls.forEach((control) => {
    control.addEventListener('click', () => {
      const video = control.parentElement.querySelector('video');
      const parent = control.parentElement.parentElement.parentElement;
      console.log(parent);

      if (video.paused) {
        video.play();
        control.style.opacity = 0;
        parent.classList.add('hover');
      } else {
        video.pause();
        control.style.opacity = 1;
        parent.classList.remove('hover');
      }
    });
  });
}

function handleTeam() {
  const members = document.querySelectorAll('.team__members__item');
  const wrapper = document.querySelector('.team__members__popup');
  const closeButton = document.querySelector('.team__members__popup__close');
  const items = document.querySelectorAll('.team__members__popup__item');
  const prevButton = document.querySelectorAll('.team__members__popup__button--prev');
  const nextButton = document.querySelectorAll('.team__members__popup__button--next');
  const buttons = document.querySelector('.team__members__popup__items');

  const open = (e) => {
    const index = Array.from(e.currentTarget.parentElement.children).indexOf(e.currentTarget);
    items.forEach((item) => {
      item.classList.remove('active');
    });
    items[index].classList.add('active');

    if (index === items.length - 1) nextButton.forEach((button) => button.classList.add('disable'));
    if (index <= 0) prevButton.forEach((button) => button.classList.add('disable'));

    const progress = (index + 1) / items.length;
    console.log(index, items.length - 1);
    buttons.style.setProperty('--progress', progress);

    instance.locoScroll.scroll.stop = true;

    wrapper.classList.add('open');
  };

  const close = () => {
    wrapper.classList.remove('open');
    nextButton.forEach((button) => button.classList.remove('disable'));
    prevButton.forEach((button) => button.classList.remove('disable'));
    instance.locoScroll.scroll.stop = false;
  };

  const goPrev = () => {
    const currentActiveItem = document.querySelector('.team__members__popup__item.active');
    const currentIndex = Array.from(currentActiveItem.parentElement.children).indexOf(currentActiveItem);
    const prevIndex = currentIndex - 1;

    nextButton.forEach((button) => button.classList.remove('disable'));

    currentActiveItem.classList.remove('active');
    items[prevIndex].classList.add('active');

    const progress = (prevIndex + 1) / items.length;
    buttons.style.setProperty('--progress', progress);
    if (prevIndex <= 0) prevButton.forEach((button) => button.classList.add('disable'));
  };

  const goNext = () => {
    const currentActiveItem = document.querySelector('.team__members__popup__item.active');
    const currentIndex = Array.from(currentActiveItem.parentElement.children).indexOf(currentActiveItem);
    const nextIndex = currentIndex + 1;

    prevButton.forEach((button) => button.classList.remove('disable'));

    currentActiveItem.classList.remove('active');
    items[nextIndex].classList.add('active');

    const progress = (nextIndex + 1) / items.length;
    buttons.style.setProperty('--progress', progress);
    if (nextIndex === items.length - 1) nextButton.forEach((button) => button.classList.add('disable'));
  };

  members.forEach((member) => {
    member.addEventListener('click', open);
  });

  closeButton.addEventListener('click', close);

  prevButton.forEach((button) => button.addEventListener('click', goPrev));
  nextButton.forEach((button) => button.addEventListener('click', goNext));
}

export { handleNav, teamPortfolioResizeDesktop, teamPortfolioResizeMobile, checkForVideo, videoControls, handleTeam };
