import Swiper, { EffectFade, Navigation, Pagination, FreeMode } from 'swiper';

function reviews() {
  const reviews = new Swiper('section.reviews .swiper', {
    modules: [Navigation],
    navigation: {
      nextEl: 'section.reviews .swiper-button-next',
      prevEl: 'section.reviews .swiper-button-prev',
    },
    draggable: true,
    breakpoints: {
      768: {
        spaceBetween: 46,
      },
      1024: {
        spaceBetween: 56,
      },
    },
  });

  reviews.init();
}

function companiesSlide() {
  const companiesSlide = new Swiper('section.companies .swiper', {
    modules: [EffectFade],
    spaceBetween: 24,
    effect: 'fade',
  });

  const cards = document.querySelectorAll('.companies .company');
  const wrapper = document.querySelector('section.companies .wrapper');

  cards.forEach((card) => {
    card.addEventListener('click', () => {
      if (window.innerWidth < 768) {
        wrapper.classList.add('active');
        const cardIndex = Object.values(card.parentElement.getElementsByClassName('company')).indexOf(card);
        companiesSlide.slideTo(cardIndex);
        wrapper.showModal();
      }
    });
  });

  const closeButton = document.querySelectorAll('.swiper-slide .close');

  closeButton.forEach((button) => {
    button.addEventListener('click', () => {
      document.querySelector('section.companies .wrapper').classList.remove('active');
      wrapper.close();
    });
  });

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 768) {
      wrapper.classList.remove('active');
      wrapper.close();
    }
  });
}

function desktopTeamSlide() {
  const teamSlide = new Swiper('section.hero .swiper.desktop', {
    modules: [Navigation, EffectFade],
    navigation: {
      nextEl: 'section.hero .swiper.desktop .swiper-button-next',
      prevEl: 'section.hero .swiper.desktop .swiper-button-prev',
    },
    effect: 'fade',
    spaceBetween: 24,
  });

  if (window.innerWidth >= 768) {
    const members = document.querySelectorAll('section.hero .member');

    members.forEach((member) => {
      member.addEventListener('click', () => {
        document.querySelector('body').classList.add('active');
        const memberIndex = Object.values(member.parentElement.getElementsByClassName('member')).indexOf(member);
        window.innerWidth >= 768 ? teamSlide.slideTo(memberIndex) : '';
        teamSlide.slideTo(memberIndex);
      });
    });

    document.addEventListener('click', (e) => {
      if (!e.target.matches('section.hero .swiper-slide *') && !e.target.matches('section.hero .member *')) {
        document.querySelector('body').classList.remove('active');
      }
    });
  }
}

function mobileTeamSlide() {
  const teamSlide = new Swiper('section.hero .swiper.mobile', {
    modules: [Navigation, EffectFade],
    navigation: {
      nextEl: 'section.hero .swiper.mobile .swiper-button-next',
      prevEl: 'section.hero .swiper.mobile .swiper-button-prev',
    },
    effect: 'fade',
    spaceBetween: 24,
  });

  teamSlide.init();

  if (window.innerWidth < 768) {
    const wrapper = document.querySelectorAll('section.hero .team-members-full *');
    wrapper.forEach((child) => {
      child.addEventListener('click', () => {
        document.querySelector('body').classList.add('active');
      });
    });

    const closeButton = document.querySelectorAll('.swiper-slide .close');

    closeButton.forEach((button) => {
      button.addEventListener('click', () => {
        document.querySelector('body').classList.remove('active');
        teamSlide.slideTo(0);
      });
    });
  }
}

function imagesSlide() {
  const imagesSlide = new Swiper('section.images .swiper', {
    modules: [Pagination, FreeMode],
    pagination: {
      el: 'section.images .swiper-pagination',
      type: 'progressbar',
    },
    spaceBetween: 16,
    freeMode: true,
    grabCursor: true,
    slidesPerView: 'auto',
  });
  imagesSlide.init();
}

function newsSlide() {
  const newsSlide = new Swiper('section.news .swiper', {
    modules: [Navigation, EffectFade],
    effect: 'fade',
    navigation: {
      nextEl: 'section.news .swiper-button-next',
      prevEl: 'section.news .swiper-button-prev',
    },
    spaceBetween: 16,
    breakpoints: {
      768: {
        spaceBetween: 0,
      },
    },
    draggable: true,
  });

  newsSlide.init();
}

export { reviews, companiesSlide, desktopTeamSlide, mobileTeamSlide, newsSlide, imagesSlide };
