import barba from "@barba/core";

import { body, html } from "@utils/dom";
import { sleep } from "@utils/sleep";

const parser = new DOMParser();

// entenda como ele seleciona e como ele usa o parserFromString https://github.com/barbajs/barba/issues/507

export const updateBodyClass = (html) => {
  const source = parser.parseFromString(html, "text/html");
  const classNames = source.querySelector("body").classList;

  // aplique nova classList ao corpo
  document.body.classList = classNames;
};

// antes de sair da transição, adicione um nome de classe especial ao html
barba.hooks.beforeLeave(() => {
  body.classList.add("--js-barba");
});

barba.hooks.afterLeave(() => {
  body.removeAttribute("class");
});

barba.hooks.enter((data) => {
  window.scrollTo(0, 0);
});

// antes de entrar na transição, remova o contêiner antigo e atualize os nomes das classes do corpo
barba.hooks.beforeEnter((data) => {
  barba.transitions.remove(data);
  updateBodyClass(data.next.html);
});

// após a transição, remova o nome de classe especial dos scripts html + inject e eval
barba.hooks.after(() => {
  html.classList.remove("--js-barba");
  // ga('set', 'page', window.location.pathname);
  // ga('send', 'pageview');
  return sleep(0);
});

export default {
  updateBodyClass,
};
