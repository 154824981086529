import { $, $$ } from "@utils/dom";
import jQuery from "jquery";
import { gsap } from "gsap";
import ImagesLoaded from "@utils/imagesloaded";
import { instance } from "@ui/site-scroll";
const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.name = "loader";
  }

  beforeOnce({ next }) {
    return new Promise((resolve) => {
      this._imgLoader = new ImagesLoaded(next.container, resolve);
    });
  }

  once() {
    return new Promise((resolve) => {
      const tl = gsap.timeline();
      let count = 0;
      const el = jQuery(".preloader");
      const CTCONTAINER = jQuery(".site-loader");

      tl.to(CTCONTAINER, {
        function() {
          instance.destroy();
          jQuery(".is-inview").removeClass("is-inview");
        },
      });

      var counter = setInterval(function () {
        if (count < 101) {
          jQuery(".preloader").text(`${count}%`);
          count++;
        } else {
          clearInterval(counter);
          tl.to(
            CTCONTAINER,
            {
              y: "-100%",
              duration: 1.5,
              autoAlpha: 1,
              ease: "power4.inOut",
              function() {
                setTimeout(function () {
                  instance.init();
                }, 200);
              },
            },
            "<"
          );
          tl.to(".main-wrapper", {
            autoAlpha: 1,
          });
          tl.to(".preloader", {
            opacity: 0,
            y: "50%",
            ease: "power4.inOut",
            onStart: () => {
              resolve();
            },
            onComplete: () => {},
          });
        }
      }, 10);
    });
  }
}

export default SiteLoader;
