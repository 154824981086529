import gsap, { Sine } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import lottie from 'lottie-web';

gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false,
});

const scroller = '[data-scroll-container]';

function homeHeroAnimation() {
  lottie.loadAnimation({
    container: document.getElementById('bg-container'),
    path:
      process.env.NODE_ENV === 'development'
        ? './wp-content/themes/squareone/dist/data/home.json'
        : 'https://squareone.vc/wp-content/themes/squareone/dist/data/home.json',
    renderer: 'svg',
    loop: false,
    autoplay: true,
  });
}

function manifestoAnimation() {
  const button = document.querySelector('.manifesto .start a .btn');

  gsap.set('.manifesto .start .paragraph p', {
    display: 'none',
    y: '10%',
    opacity: 0,
  });

  gsap.set('.manifesto .animation-container', {
    display: 'none',
  });

  button.addEventListener('click', (e) => {
    e.preventDefault();

    if (window.innerWidth >= 768) {
      document.querySelector('.fixed-text').setAttribute('data-scroll-sticky', '');
      document.querySelector('.fixed-text').style.transition = 'transform .3s ease-in-out';
      setTimeout(() => {
        document.querySelector('.fixed-text').style.transition = 'none';
      }, 300);
    }

    //fix
    gsap.set(['.start .paragraph p', '.manifesto .animation-container'], {
      display: '',
    });

    // button animation on click
    gsap
      .timeline()
      .to(button, {
        opacity: 0,
        y: '50%',
        duration: 0.4,
      })
      /*.to(
        ['.start .paragraph p', '.manifesto .animation-container'],
        {
          display: '',
          duration: 0.01,
        },
        0
      )*/
      .to(
        ['.start .paragraph p', '.manifesto .animation-container'],
        {
          // y: 0,
          opacity: 1,
          duration: 0.8,
        },
        0.25
      );

    // paragraph mask animation
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.start .paragraph',
          scroller: scroller,
          start: 'top +=15%',
          end: 'center +=15%',
          scrub: true,
        },
      })
      .to(':root', {
        '--mask-height': '0',
      });

    // changing text animation appearing
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.animation-container .changing-text',
          scroller: scroller,
          start: 'center +=90%',
          end: 'center +=60%',
          scrub: true,
        },
      })
      .to('.manifesto .start .fixed-text h3', {
        opacity: 0.5,
      })
      .to(
        '.manifesto .start',
        {
          y: '-5rem',
          opacity: 0,
        },
        0.25
      );

    // pinning and changing the text
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.animation-container .changing-text',
          scroller: scroller,
          start: 'center center',
          end: '+=150%',
          pin: true,
          scrub: true,
        },
      })
      .to(
        '.changing-text .word span:nth-child(1)',
        {
          top: '-100%',
          opacity: 0,
        },
        0.1
      )
      .to(
        '.changing-text .word span:nth-child(2)',
        {
          top: '0%',
          opacity: 1,
        },
        '<10%'
      )
      .to('.changing-text .word span:nth-child(2)', {
        top: '-100%',
        opacity: 0,
      })
      .to(
        '.changing-text .word span:nth-child(3)',
        {
          top: '0%',
          opacity: 1,
        },
        '<10%'
      )
      .to('.changing-text .word span:nth-child(3)', {
        top: '-100%',
        opacity: 0,
      })
      .to(
        '.changing-text .word span:nth-child(4)',
        {
          top: '0%',
          opacity: 1,
        },
        '<10%'
      )
      .to('.changing-text .word span:nth-child(4)', {
        top: '0%',
        opacity: 1,
      });

    // images slide
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.animation-container .images',
          scroller: scroller,
          start: 'top +=90%',
          end: 'bottom top',
          // markers: true,
          scrub: true,
        },
      })
      .to('.animation-container .images', {
        x: '-300vmax',
        ease: 'none',
      });

    // promises pinning
    ScrollTrigger.create({
      trigger: '.animation-container .promises',
      scroller: scroller,
      start: 'top +=3%',
      end: '+=20%',
      scrub: true,
    });

    // last text appeears and logo scroll
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.animation-container .logo',
          scroller: scroller,
          start: 'center center',
          end: '+=100%',
          pin: true,
          scrub: true,
        },
      })
      .set('.animation-container .logo', {
        x: window.innerWidth > 1024 ? '' : '50%',
      })
      .to('.animation-container .logo', {
        x: window.innerWidth > 1024 ? '-60%' : '0%',
      });
  });
}

function portfolioHeroAnimation() {
  lottie.loadAnimation({
    container: document.getElementById('bg-container'),
    path:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/wp-content/themes/squareone/dist/data/portfolio.json'
        : 'https://squareone.vc/wp-content/themes/squareone/dist/data/portfolio.json',
    renderer: 'svg',
    loop: false,
    autoplay: true,
  });
}

function setTeamHeroAnimation() {
  gsap.set('section.hero', { overflow: 'hiddden' });
  gsap.set('section.hero span:nth-child(1)', { opacity: 0, y: '-200%' });
  gsap.set('section.hero span:nth-child(2)', { opacity: 0, y: '-190%' });
  gsap.set('section.hero h1 svg', { opacity: 0, y: '20%' });
  gsap.set('section.hero .team-members-full', { y: '200%', x: '-50%' });
  gsap.set('.team-members-full .tap-to-view', { opacity: 0 });
}

function teamHeroAnimation() {
  gsap
    .timeline({ defaults: { ease: Sine.easeOut, duration: 0.9 } })
    .to('nav.navbar', { opacity: 1, y: '0%' })
    .to('section.hero h1 span:nth-child(1)', { opacity: 1, y: '5%' })
    .to('section.hero h1 span:nth-child(2)', { opacity: 1, y: '100%' }, '+=10%')
    .to('section.hero h1 svg', { opacity: 1, y: '200%' }, '+=20%')
    .to('section.hero', { overflow: 'visible', duration: 0 })
    .to('section.hero h1 span', { y: '0%' })
    .to('section.hero h1 svg', { y: '0%', duration: 0.4 }, '<')
    .to('.team-members-full', { y: '0%' }, '<')
    .to('.team-members-full .tap-to-view', { opacity: 1, duration: 0.5 });
}

function teamNewsAnimation() {
  const square = document.querySelector('section.animation .square');
  const squarePositions = {
    translate: '',
    bottom: '',
    right: '',
  };
  const breakpoints = () => {
    if (window.innerWidth < 768) {
      squarePositions.translate = '-106% -50%';
      squarePositions.bottom = -window.innerHeight / 2 + square.getBoundingClientRect().height;
      squarePositions.right = -window.innerWidth / 2 + square.getBoundingClientRect().width;
    } else if (window.innerWidth <= 1024) {
      squarePositions.translate = '100% -25%';
      squarePositions.bottom = -window.innerHeight / 2 + square.getBoundingClientRect().height;
      squarePositions.right = window.innerWidth / 2 + square.getBoundingClientRect().width;
    } else {
      squarePositions.translate = '87.5% -31%';
      squarePositions.bottom = -window.innerHeight / 2 - square.getBoundingClientRect().width / 2;
      squarePositions.right = window.innerWidth / 2 + square.getBoundingClientRect().width;
    }
  };
  breakpoints();
  gsap
    .timeline({
      scrollTrigger: {
        trigger: 'section.animation',
        scroller: scroller,
        start: 'top top',
        end: window.innerWidth >= 768 ? '+=140%' : '+=120%',
        scrub: true,
        pin: true,
      },
    })
    .to(square, {
      scale: 1,
      bottom: squarePositions.bottom,
      translate: squarePositions.translate,
      right: squarePositions.right,
      rotate: 90,
      backgroundColor: '#c8dacc',
      force3D: false,
    })
    .to(
      'main',
      {
        backgroundColor: '#223b38',
      },
      '<30%'
    )
    .to('section.animation .square', {
      scale: '+=30',
      force3D: false,
    })
    .to(
      'section.animation',
      {
        opacity: 0,
      },
      '<'
    );
  gsap
    .timeline({
      scrollTrigger: {
        trigger: 'section.news',
        scroller: scroller,
        start: 'top center',
      },
    })
    .to('section.news .swiper-slide', {
      stagger: 0.2,
      top: 0,
      opacity: 1,
    });
}

function footerOverlay() {
  // ScrollTrigger.create({
  //   scroller: scroller,
  //   trigger: '.manifesto',
  //   start: 'bottom bottom',
  //   pin: true,
  //   pinSpacing: false,
  // });
  // ScrollTrigger.create({
  //   scroller: scroller,
  //   trigger: '.site-footer',
  //   start: 'bottom bottom',
  //   pin: true,
  //   pinSpacing: false,
  // });
}

export {
  manifestoAnimation,
  footerOverlay,
  setTeamHeroAnimation,
  teamHeroAnimation,
  teamNewsAnimation,
  homeHeroAnimation,
  portfolioHeroAnimation,
};
