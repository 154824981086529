import {
  handleNav,
  teamPortfolioResizeDesktop,
  teamPortfolioResizeMobile,
  checkForVideo,
  videoControls,
  handleTeam,
} from '../modules/tricks';
import {
  manifestoAnimation,
  footerOverlay,
  setTeamHeroAnimation,
  teamHeroAnimation,
  teamNewsAnimation,
  homeHeroAnimation,
  portfolioHeroAnimation,
} from '../ui/animations';
import { reviews, companiesSlide, desktopTeamSlide, mobileTeamSlide, newsSlide, imagesSlide } from '../modules/slide';

export default [
  {
    namespace: 'home',
    beforeEnter() {
      handleNav();
    },
    afterEnter() {
      manifestoAnimation();
      footerOverlay();
      homeHeroAnimation();
    },
  },
  {
    namespace: 'page-templates/portfolio',
    beforeEnter() {
      handleNav();
      reviews();
      companiesSlide();
    },
    afterEnter() {
      portfolioHeroAnimation();
    },
  },
  {
    namespace: 'page-templates/team',
    beforeEnter() {
      handleNav();
      teamPortfolioResizeDesktop();
      teamPortfolioResizeMobile();
      desktopTeamSlide();
      mobileTeamSlide();
      newsSlide();
      setTeamHeroAnimation();
      imagesSlide();
    },
    afterEnter() {
      teamHeroAnimation();
      // teamNewsAnimation();
      handleTeam();
    },
  },
  {
    namespace: 'page-templates/cases',
    beforeEnter() {
      handleNav();
      checkForVideo();
      videoControls();
    },
  },
  {
    namespace: 'blog',
    beforeEnter() {
      console.log('About page');
    },
  },
];
