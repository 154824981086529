const { THEME_NAME } = process.env;

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(`/${THEME_NAME}/wp-content/themes/${THEME_NAME}/dist/sw.js`)
      .then((registration) => {
        // console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
